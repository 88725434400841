import { render, staticRenderFns } from "./VxAutoSuggest.vue?vue&type=template&id=93a601d8&"
import script from "./VxAutoSuggest.vue?vue&type=script&lang=js&"
export * from "./VxAutoSuggest.vue?vue&type=script&lang=js&"
import style0 from "./VxAutoSuggest.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports