<template>
  <div>
    <!-- {{this.$store.state.AppActiveUser}} -->
    <template v-if="typeof this.$store.state.AppActiveUser !== 'undefined'">

      <template v-if="typeof this.$store.state.AppActiveUser.uid !== 'undefined' && typeof this.$store.state.AppActiveUser.user_id !== 'undefined'">
        <template v-if="this.$store.state.AppActiveUser.uid === 1 || this.$store.state.AppActiveUser.user_id === null || this.$store.state.AppActiveUser.user_id === 0 || this.$store.state.AppActiveUser.user_id === ''">
          <vs-button to="/" color="primary" type="line" icon-pack="feather" icon="icon-log-in">
            <div class="hidden sm:block">{{$t('Login')}}</div>
          </vs-button>
        </template>
      </template> 

      <template v-if="typeof this.$store.state.AppActiveUser.uid !== 'undefined' && typeof this.$store.state.AppActiveUser.user_id !== 'undefined'">

        <template v-if="this.$store.state.AppActiveUser.uid !== 1 && this.$store.state.AppActiveUser.user_id !== null && this.$store.state.AppActiveUser.user_id !== 0 && this.$store.state.AppActiveUser.user_id !== ''">
          
          <user-sidebar :isSidebarActive="myProfileEdition" @closeSidebar="myProfileEdition = false" :data="this.$store.state.AppActiveUser" />

          <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
            <div class="text-right leading-tight hidden sm:block">
              <p class="font-semibold">{{ activeUserInfo.displayName | capitalize }}</p>
            </div>

            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

              <div class="con-img ml-3" :key="'avatar_'+this.$store.state.current_user_avatar_increment">
                <user-avatar 
                :key="this.$store.state.current_user_avatar_increment"
                :user="this.$store.state.AppActiveUser"
                size="large"
                />
              </div>

              <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 9rem">

                  <template v-if="this.$store.state.AppActiveUser.email_verified">
                    <li
                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="myProfileEdition = !myProfileEdition">
                      <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                      <span class="ml-2">{{$t('Profile')}}</span>
                    </li>

                    <li
                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="gotoDiagnostic()">
                      <feather-icon icon="CheckCircleIcon" svgClasses="w-4 h-4" />
                      <span class="ml-2">{{$t('Diagnostic')}}</span>
                    </li>

                    <vs-divider class="m-1" />
                  </template>

                  <li
                    class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                    @click="logout">
                    <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                    <span class="ml-2">{{$t('Logout')}}</span>
                  </li>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>   
      </template>
    </template>
  </div>
</template>


<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import UserSidebar from '../../../../views/components/UserSidebar.vue'


export default {
  components: {
    UserSidebar
  },
  data () {
    return {
      myProfileEdition: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    // ROYBON / Image par défaut si 404
    imageUrlAlt (event) {
      event.target.src = `${this.$appConfig.imgBaseUrl}/user/avatar/undefined/thumb_150_150.jpg`
    },
    gotoDiagnostic () {
      this.$router.push('/diagnostic').catch(() => {})
    },
    logout () {
      // if user is logged in via auth0
      if (this.$auth.profile) this.$auth.logOut()
      this.$auth.logOut()

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        firebase.auth().signOut().then(() => {
          this.$router.push('/pages/login').catch(() => {})
        })
      }
      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
        this.$router.push('/pages/login').catch(() => {})
      }
    }
  }
}
</script>
