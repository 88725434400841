var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mounted && (_vm.isSuperAdmin || _vm.isCustomerServices))?_c('div',{staticClass:"layout--backoffice",class:[_vm.layoutTypeClass, _vm.navbarClasses, _vm.footerClasses, { 'no-scroll': _vm.isAppPage }]},[_c('v-nav-menu',{attrs:{"displaySidebar":_vm.mounted,"navMenuItems":_vm.navMenuItems,"title":"RIM-Nat","parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
                { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
                { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark }
            ],attrs:{"navbarType":_vm.navbarType}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
                { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
                { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark }
            ],attrs:{"navMenuItems":_vm.navMenuItems}})]:[_c('the-navbar-vertical',{class:[
                { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
                { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark }
            ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap items-center mb-6"},[_c('div',{staticClass:"content-area__heading",class:{ 'pr-4 border-0 md:border-r border-solid border-grey-light': _vm.$route.meta.breadcrumb }},[_c('h2',{staticClass:"mb-1"},[_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"SettingsIcon","svgClasses":"h-4 w-4"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.routeTitle))+" ")],1)]),(_vm.$route.meta.breadcrumb)?_c('vx-breadcrumb',{staticClass:"ml-4 md:block hidden",attrs:{"route":_vm.$route,"isRTL":_vm.$vs.rtl}}):_vm._e()],1):_vm._e()]),(_vm.isSuperAdmin || _vm.isCustomerServices)?_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px',"visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle,"setAppClasses":function (classesStr) { return _vm.$emit('setAppClasses', classesStr); }}})],1)],1):_c('div',[_vm._v("restricted content")])],1)])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }