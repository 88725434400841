<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <span class="cursor-pointer flex items-center i18n-locale mt-1 sm:mr-6 mr-2">
      <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class="hidden sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
    </span>
    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
      <vs-dropdown-item @click="updateLocale('fr')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/fr.png" alt="fr" /> &nbsp;Français</vs-dropdown-item>
      <vs-dropdown-item @click="updateLocale('en')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" /> &nbsp;English</vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import axios from '@/axios'

export default {
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      updateUserIdentity: 'updateUserIdentity'
    }
  },
  computed: {
    i18n_locale_img () {
      return require(`@/assets/images/flags/${this.$i18n.locale}.png`)
    },
    getCurrentLocaleData () {
      const locale = this.$i18n.locale
      if (locale === 'en')      return { flag: 'us', lang: 'English'    }
      else if (locale === 'fr') return { flag: 'fr', lang: 'Français'     }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$i18n.locale = locale
      this.$store.dispatch('updateUserInfo', {
        user_lang: locale
      })
      const currentUserId = this.$store.state.AppActiveUser.user_id
      if (currentUserId !== null && !isNaN(currentUserId)) {
        // if user is logged in : update his profile
        //const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_lang=${locale}`
        const params = new FormData()
        params.set('user_id', `${this.$store.state.AppActiveUser.user_id}`)
        params.set('user_lang', `${locale}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.updateUserIdentity}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('profileUpdated')
              })
            }
          })
          .catch((error)   => { console.log(error) }
          )
      }
    }
  }
}
</script>
